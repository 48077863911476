import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { CountdownTimer, Loader } from "@storybook";
import { useLogin, useMobileAuthentication } from "../../store/hooks";
import { useNotification } from "hooks";
import { useCookie } from "hooks/cookie";
import { LoginRoutesState, MobileVerificationState, sendCodeTimerState, userLoggedInDetails, userTokenState } from "../../store/state";
import { Json } from "types";
import { addSeconds } from "date-fns";
import {  useFirebase } from "libs";

export const MobileLinkSent = () => {
  const verificationId = useRecoilValue(MobileVerificationState);
  const userAuthDetails = useRecoilValue(userLoggedInDetails);
  const { phone, countryCode }: any = userAuthDetails;
  const { checkMobileVerificationStatus } = useMobileAuthentication();
  const { successNotification, errorNotification } = useNotification();
  const { set: setCookieToken } = useCookie();
  const { generateVerificationLink } = useMobileAuthentication();
  const { handleOnSuccess } = useLogin();
  const { fetchDataWithToken } = useFirebase();

  const setUserToken = useSetRecoilState(userTokenState);
  const seLoginRoutes = useSetRecoilState(LoginRoutesState);

  const [sendCodeTimer, setSendCodeTimer] = useRecoilState(sendCodeTimerState);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);

  let mobileLinkVerifyInterval: any = useRef(null);
  const loginUser = (token: string) => {
    const loginDetails = {
      token,
      isLoggedIn: true,
    };
    if (token) {
      setCookieToken("userDetails", loginDetails);
      localStorage.setItem("token", JSON.stringify(token));
      fetchDataWithToken(token);
      setUserToken(token);
      handleOnSuccess();
      successNotification("Login successfully");
    }
  };

  const checkStatus = async () => {
    const payload = {
      type: "getTokenWithCode",
      verificationId,
    };

    const res: any = await checkMobileVerificationStatus(payload);
    if (res) {
      // const { status } = res?.data;
      if (
        res?.apiData?.data?.token &&
        res?.apiData?.data?.status === "approved"
      ) {
        // stop the interval and login the user
        clearInterval(mobileLinkVerifyInterval.current);
        loginUser(res?.apiData?.data?.token);
      }
      if (res?.apiData?.data?.status === "rejected") {
        // stop the interval and login the user
        clearInterval(mobileLinkVerifyInterval.current);
        errorNotification("Mobile verification failed");
        seLoginRoutes("chooseMethod");
      }
    }
  };

  useEffect(() => {
    if (verificationId) {
      mobileLinkVerifyInterval.current = setInterval(() => {
        checkStatus();
      }, 3000);
    }

    return () => clearInterval(mobileLinkVerifyInterval?.current);
  }, [verificationId]);

  const cancelApproval = () => {
    clearInterval(mobileLinkVerifyInterval.current);
    seLoginRoutes("chooseMethod");
  };

  const handleResendOTP = useCallback(async () => {
    setSendOtpLoading(true);
    const { countryCode, phone } = userAuthDetails;
    const verificationId = await generateVerificationLink({
      type: "phone",
      countryCode: countryCode,
      phone: phone,
    });
    if (verificationId) successNotification("SMS sent successfully");
    setSendOtpLoading(false);
    setSendCodeTimer(addSeconds(new Date(), 30));
  }, []);

  const renderTimer = useCallback(({ seconds, completed }: Json) => {
    if (completed) setSendCodeTimer(null);
    return `${(seconds < 10 ? "0" : "") + seconds}s`;
  }, []);

  return (
    <div className="mobile-link">
      <div className="mobile-link__wrapper">
        <div className="mobile-link__row">
          <div className="mobile-link__row mobile-link__row-1 mobile-link__vertical-space">
            <Loader className="loader-blue" dimension={60} />
            {/* <div className="mobile-link__number">{securityCode}</div>
          <div className="mobile-link__specification">
            Tap <strong>{securityCode}</strong> on your phone to verify your
            phone number{" "}
          </div> */}
          </div>
          <div className="mobile-link__title">Waiting for approval</div>
          <div className="mobile-link__description">
            {/* {`We have sent you a text message to ${country} ${phone} . Open the link
                provided in the text message`} */}
            We have sent you a text message on
            <strong className="mobile-link__mobile-number">
              {" "}
              {countryCode} {phone}{" "}
            </strong>
          </div>
          <div className="mobile-link__description">
            Kindly open the link provided in the text message and approve from
            there.
          </div>
        </div>
        <div className="mobile-link__wrapper__row">
          <button onClick={cancelApproval}>Back</button>
        </div>
        <div className="resend d">
          {!sendCodeTimer && (
            <>
              {`Not received the code yet? `}
              <span className="editBtn" onClick={handleResendOTP}>
                {sendOtpLoading ? <Loader dimension={18} /> : "Resend"}
              </span>
            </>
          )}

          {!!sendCodeTimer && (
            <>
              {`Resend OTP in `}
              <span className="sendCodeTimer">
                <CountdownTimer
                  dateTime={sendCodeTimer}
                  renderer={(value) => renderTimer(value)}
                />
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
