import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  LoginRoutes,
  AuctionTable,
  UserDetails,
  PrivateRoute,
  PublicRoute,
  Roles,
  ActivityLog,
  Revenue,
  Accounts,
  Invite,
  NoAccess,
  SbaAsset,
  DVP,
  AppManagement,
} from "views";
import { APP_ROUTES } from "./store";
import { MintAsset } from "views/mint-asset";
import {
  newRoleState,
  selectedRoleState,
  userDetailsState,
} from "views/roles/store";
import { useCookie, useNetwork, useAccess } from "hooks";
import { OauthRedirectHandler } from "views/login/OauthRedirectHandler";
import MobileApprove from "views/login/component/mobile-approve/mobile-approve";
import { API_URL } from "constant";
import { AccessDenied } from "../../@storybook/access-denied";
import { useUserPermission } from "hooks/user-permission";
import { Loader } from "@storybook";
import { Chats } from "views/chats";
import { ChatDetails } from "views/chats/components/chatDetails";
import { SBA7A } from "layouts";
import { isPP, isStage, isBeta } from "views/accounts/components/sessionDetailsModal";

const {
  USER_LOGIN,
  NO_ROUTE,
  USER_DETAILS,
  AUCTION,
  ROLES,
  ACTIVITY_LOGS,
  REVENUE,
  ACCOUNTS,
  MINTED_ASSETS,
  REAL_ESTATE,
  SBA7,
  API_DESIGNER,
  OAUTH,
  ACCEPT_INVITE,
  ACCESS_DENIED,
  USER_LOGIN_APPROVE,
  CHATS,
  CHAT_DETAILS,
  DP,
  MANU_HOME_LOANS,
  EQUIPMENT_FINANCE,
  APP
} = APP_ROUTES;

export const AllRoutes = () => {
  const setVisibleNewRole = useSetRecoilState(newRoleState);
  const selectedRole = useRecoilValue(selectedRoleState);
  const setUserAccess = useSetRecoilState(userDetailsState);

  const [userLoaded, setUserLoaded] = useState(false);

  const { get } = useCookie();
  const location = useLocation();
  const { isAccess } = useAccess();

  const {
    get: getUserDetail,
    data: userData,
    isLoaded: loadedUser,
  } = useNetwork();

  const userDetails = get("userDetails") ?? {};
  const { deleteCookie } = useCookie();
  const buttonDisable = useUserPermission();

  const handelAddNewRole = useCallback(() => {
    setVisibleNewRole(true);
  }, []);

  // Do not remove this useEffect.This is being used in login flow
  useEffect(() => {}, [location]);

  useEffect(() => {
    if (userDetails?.token) {
      getUserDetail(API_URL.USER_DETAILS).then((res: any) => {
        if (res?.response?.status === 403) {
          if (res?.apiData?.data?.removedAccess === true) {
            deleteCookie("userDetails");
          }
        }
      });
    }
  }, [deleteCookie, getUserDetail, userDetails?.token]);

  useEffect(() => {
    if (userData) {
      setUserAccess(userData);
      setUserLoaded(loadedUser);
    }
  }, [loadedUser, setUserAccess, userData]);

  const defaultRoute = useMemo(() => {
    switch (true) {
      case isAccess(ACCOUNTS):
        return ACCOUNTS;
      case isAccess("Revenue & Analytics"):
        return REVENUE;
      case isAccess("Private Stock"):
        return MINTED_ASSETS;
      case isAccess("Auction"):
        return AUCTION;
      case isAccess("API Designer"):
        return API_DESIGNER;
      case isAccess("Activity Logs"):
        return ACTIVITY_LOGS;
      case isAccess("Chats"):
        return CHATS;
      case isAccess(DP):
        return DP;
        
      case isAccess("Roles & permissions"):
        return ROLES;
      default:
        return ACCESS_DENIED;
    }
  }, [isAccess]);

  return (
    <Routes>
      {!userDetails?.token ? (
        <>
          <Route path={USER_LOGIN_APPROVE} element={<MobileApprove />} />
          <Route path={USER_LOGIN} element={<LoginRoutes />} />
          <Route path={OAUTH} element={<OauthRedirectHandler />} />
          <Route path={ACCEPT_INVITE} element={<Invite />} />
          <Route path={`${NO_ROUTE}*`} element={<Navigate to={USER_LOGIN} />} />
        </>
      ) : (
        <>
          {userLoaded ? (
            <>
              <Route
                path={`${NO_ROUTE}*`}
                element={<Navigate to={defaultRoute} />}
              />
              <Route path={ACCESS_DENIED} element={<AccessDenied />} />
              <Route
                path={ACCOUNTS}
                element={
                  <PrivateRoute
                    element={
                      isAccess(ACCOUNTS) ? (
                        <Accounts />
                      ) : (
                        <NoAccess module={ACCOUNTS} />
                      )
                    }
                    title={"Accounts"}
                  />
                }
              />

            <Route         
                path={DP}
                element={
                  <PrivateRoute
                    element={
                      isAccess("DVP") ? <DVP /> : <NoAccess module={"DVP"} />
                    }
                    title={"DVP"}
                  />
                }
              />


              <Route
                path={AUCTION}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Auction") ? (
                        <AuctionTable />
                      ) : (
                        <NoAccess module={"Auction"} />
                      )
                    }
                    title={"Auction details"}
                  />
                }
              />  
              <Route
                path={API_DESIGNER}
                element={
                  <PrivateRoute
                    element={
                      isAccess("API Designer") ? (
                        <AuctionTable />
                      ) : (
                        <NoAccess module={"API Designer"} />
                      )
                    }
                    title={"API Designer"}
                  />
                }
              />
              <Route
                path={MINTED_ASSETS}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Private Stock") ? (
                        <MintAsset type="privates" />
                      ) : (
                        <NoAccess module={"Private Stock"} />
                      )
                    }
                    title={"Private Stock"}
                  />
                }
              />
              <Route
                path={REAL_ESTATE}
                element={
                  <PrivateRoute
                    element={
                      isAccess("REAL ESTATE") ? (
                        <MintAsset type="real_estate" />
                      ) : (
                        <NoAccess module={"Real Estate"} />
                      )
                    }
                    title={"Real Estate"}
                  />
                }
              />
              <Route
                path={SBA7}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Private Stock") ? (
                        <SbaAsset type="sba7" />
                      ) : (
                        <NoAccess module={"SBA7"} />
                      )
                    }
                    title={SBA7A}
                  />
                }
              />
              <Route
                path={MANU_HOME_LOANS}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Manufactured Home Loans") ? (
                        <SbaAsset type="manufactured_home_loans" />
                      ) : (
                        <NoAccess module={"Manufactured Home Loans"} />
                      )
                    }
                    title={"Manufactured home loan"}
                  />
                }
              />
              <Route
                path={EQUIPMENT_FINANCE}
                element={
                  <PrivateRoute
                    element={
                      isAccess("EQUIPMENT FINANCE") ? (
                        <SbaAsset type="equipment_finance" />
                      ) : (
                        <NoAccess module={"Equipment Finance"} />
                      )
                    }
                    title={"Equipment Finance"}
                  />
                }
              />
              <Route
                path={ROLES}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Roles & permissions") ? (
                        <Roles />
                      ) : (
                        <NoAccess module={"Roles and permissions"} />
                      )
                    }
                    title={"Roles and Permissions"}
                    showBtn={!selectedRole?._id}
                    btnLabel={"Add new role"}
                    btnClass="add-new-role"
                    handleClickBtn={handelAddNewRole}
                    disabled={buttonDisable}
                  />
                }
              />
              {(isStage || isPP || isBeta) && <Route
                path={APP}
                element={
                  <PrivateRoute
                    element={ <AppManagement /> }
                    title="App management"
                    btnClass="add-new-role"
                  />
                }
              />}
              <Route
                path={REVENUE}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Revenue & Analytics") ? (
                        <Revenue />
                      ) : (
                        <NoAccess module={"Revenue & Analytics"} />
                      )
                    }
                    title={"Revenue & Analytics"}
                  />
                }
              />
              <Route
                path={USER_DETAILS}
                element={<PrivateRoute element={<UserDetails />} />}
              />
              <Route
                path={ACTIVITY_LOGS}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Activity Logs") ? (
                        <ActivityLog />
                      ) : (
                        <NoAccess module={"Activity Logs"} />
                      )
                    }
                    title={"Activity Logs"}
                  />
                }
              />
              <Route
                path={CHATS}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Chats") ? (
                        <Chats />
                      ) : (
                        <NoAccess module={"Chats"} />
                      )
                    }
                    title={"Chats"}
                  />
                }
              />
              <Route
                path={CHAT_DETAILS}
                element={
                  <PrivateRoute
                    element={
                      isAccess("Chats") ? (
                        <ChatDetails />
                      ) : (
                        <NoAccess module={"Chats"} />
                      )
                    }
                    title={""}
                  />
                }
              />
              <Route
                path="*"
                element={<PublicRoute element={<LoginRoutes />} />}
              />
            </>
          ) : (
            <Route
              path={`${NO_ROUTE}*`}
              element={
                <div style={{ height: "100vh" }}>
                  <Loader />
                </div>
              }
            />
          )}
        </>
      )}
    </Routes>
  );
};
