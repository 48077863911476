import { Button, Input, ReactDropdown } from "@storybook";
import "./lockin-period.scss";
import { FC, useCallback, useEffect, useState } from "react";
import { useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { convertToDays } from "utils";

const option = [
  { label: "No Lock In", value: 0 },
  { label: "1 week", value: 7 },
  { label: "2 weeks", value: 14 },
  { label: "1 month", value: 31 },
  { label: "3 months", value: 93 },
  { label: "6 months", value: 186 },
  { label: "12 months", value: 365 },
  { label: "Custom", value: "custom" },
];

const option2 = [
  { label: "Day", value: "days" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

const daysMap = [0 ,7, 14, 31, 93, 186, 365];

export const LockinPeriod: FC<any> = ({selectedAsset, closeLoackInModal, closeLoackInModalNoSave})=> {
  const [lockInValue, setLockInValue] = useState<any>({ label: "No Lock In", value: 0 });
  const [customValue, setCustomValue] = useState<any>(null);
  const [customDropDownValue, setCustomDropDownValue] = useState<any>({ label: "Day", value: "day" });
  const [isUserInpurDataNotValid, setIsUserInpurDataNotValid] = useState(false);
  const {put: setLockIn, isLoaded} = useNetwork();
   
  useEffect(() => {
      const { lockInPeriod } = selectedAsset;
      if(Object.keys(lockInPeriod).length === 0){
        setLockInValue({ label: "No Lock In", value: 0 })
        return;
      }

      const { duration, unit } = lockInPeriod;
      const value =  daysMap.indexOf(duration) > -1;
     
     if (value) {
       const filteredObject = option?.filter(
         (item: any) => item.value === duration
       );
       setLockInValue({
         label: filteredObject[0]?.label,
         value: filteredObject[0]?.value,
       });
     } else {
       setLockInValue({ label: "Custom", value: "custom" });
       setCustomValue(duration);
       setCustomDropDownValue({
         label: unit.charAt(0).toUpperCase() + unit.slice(1),
         value: unit,
       });
     }

      return () => {
        setLockInValue({ label: "No Lock In", value: 0 });
        setCustomValue(null);
        setCustomDropDownValue({label: "Day", value: "day" })
      }
  }, [])

  
  const getPayload = useCallback(() => {
    if(lockInValue.value !== 'custom'){
      return {"duration" : lockInValue.value, "unit": "days"}
    }
    else {
      return {"duration" : Number(customValue), "unit": customDropDownValue.value}
    }
  }, [lockInValue, customValue, customDropDownValue])

  const {errorNotification, successNotification} = useNotification();

  const checkValidDate = () => {
    return 1825 >= convertToDays({duration: Number(customValue), unit: customDropDownValue?.value })
  }

  const saveLockInPeriod = () => {
    let isDateValid = true;
    if(lockInValue.value === 'custom'){
      isDateValid = checkValidDate();
    }
    
    if(isDateValid) {
      setIsUserInpurDataNotValid(false)
      setLockIn(`${API_URL.ASSETS}/${selectedAsset?.id}`, {
        lockInPeriod: getPayload(),
      }).then((res: any) => {
        if (res?.apiData?.message === "ok") {
          // do something
         closeLoackInModal();
         successNotification('Lock-in period set successfully')
        } else {
          // show error
          errorNotification('There was an unexpected error')
        }
    })
    }
    else{
      setIsUserInpurDataNotValid(true)
    }
  }

  const setCustomValueInput = (e: any) => {
    const regex = /^[0-9]{0,8}$/;
    let result = regex.test(e.target.value);
    if (!result) return;
    setCustomValue(e.target.value);
  }

  return (
    <div className="lockin-modal">
      <div className="lockin-modal-header">
        <h2>Lock-In Period</h2>
        <p>Configure lock-in requirements for this asset type.</p>
      </div>
      <div className="lockin-modal-body">
        <div className="details">
          <h3>Primary Trade</h3>
          <p className="asset-type">Private Stock</p>
        </div>
        <div className="details">
          <h3>Lock In Period</h3>
          <p>
            Define the minimum holding period required before an asset becomes
            eligible for trading again. This ensure asset stability and
            long-term investment.
          </p>
          <h4>Lock In Period</h4>
          <div className="input-group">
            <ReactDropdown
              options={option}
              isSearchable={false}
              value={lockInValue}
              handleChangeSelect={(value) => setLockInValue(value)}
            />
            {lockInValue.value === "custom" && (
              <div className="input-custom">
                <Input
                  inputType="tel"
                  label=""
                  handleChange={setCustomValueInput}
                  value={customValue}
                  placeholder=""
                  isError={isUserInpurDataNotValid}
                  errorMessage="The lock-in period cannot be more than 5 years"
                />
                <ReactDropdown
                  options={option2}
                  value={customDropDownValue}
                  isSearchable={false}
                  handleChangeSelect={(value) => setCustomDropDownValue(value)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="lockin-modal-footer">
        <Button
          label={"Cancel"}
          className="button__filled--secondary"
          handleClick={closeLoackInModalNoSave}
        />
        <Button
          label={"Save"}
          loader={isLoaded}
          disabled={
            !isLoaded || (lockInValue.value === "custom" && !customValue)
          }
          className="button__filled--primary"
          handleClick={saveLockInPeriod}
        />
      </div>
    </div>
  );
};
